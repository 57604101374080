import React, { useState } from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
// @material-ui/icons
import Email from '@material-ui/icons/Email';
import People from '@material-ui/icons/Person';
import Location from '@material-ui/icons/LocationOn';
import Username from '@material-ui/icons/FolderShared';
import Phone from '@material-ui/icons/PhoneEnabled';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import { Trans } from 'react-i18next';

// core components
import Header from '../../components/Header/Header.js';
import HeaderLinks from '../../components/Header/HeaderLinks.js';
import Footer from '../../components/Footer/Footer.js';
import GridContainer from '../../components/Grid/GridContainer.js';
import GridItem from '../../components/Grid/GridItem.js';
import Button from '../../components/CustomButtons/Button.js';
import Card from '../../components/Card/Card.js';
import CardBody from '../../components/Card/CardBody.js';
import CardHeader from '../../components/Card/CardHeader.js';
import CardFooter from '../../components/Card/CardFooter.js';
import CustomInput from '../../components/CustomInput/CustomInput.js';

import styles from '../../assets/jss/nextjs-material-kit/pages/loginPage.js';

import image from '../../assets/img/signup-bg.jpg';
import lgBg from '../../assets/img/login-background.svg';
import brainBG from '../../assets/img/bg-light.png';
import HttpClient from '../../helpers/HttpClient';
import Config from '../../Config';
import MT from '../../helpers/MT';
import UserActions from '../../store/User/actions';
import LockIcon from '@material-ui/icons/LockOutlined';
import { useDispatch } from 'react-redux';
import * as md5 from 'md5';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles(styles);

export default function Signup(props) {
  const [cardAnimaton, setCardAnimation] = React.useState('cardHidden');
  const dispatch = useDispatch();
  const [errorMsg, setErrorMsg] = React.useState('');
  const [successMsg, setSuccessMsg] = React.useState('');
  const [validationMode, setValidationMode] = React.useState(false);
  const [uiMode, setUiMode] = useState('signup');
  const [state, updateState] = useState({
    email: '',
    firstname: '',
    lastname: '',
    password: '',
    confPassword: '',
    token: '',
  });

  function ErrorAlert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const { email, firstname, lastname, password, confPassword } = state;

  const openErrorMsgSnackbar = msg => {
    setErrorMsg(msg);
  };

  const openSuccessSnackbar = msg => {
    setSuccessMsg(msg);
  };

  const handleSuccessMsgClose = () => {
    setSuccessMsg('');
  };

  const handleErrorMsgClose = () => {
    setErrorMsg('');
  };

  MT.Subscribe(Config.MT_CHANNEL_REGISTERED, user => {
    UserActions.login(user)(dispatch);
    props.history.push('/dashboard');
  });

  const sendVerify = () => {
    if (!email || !firstname || !lastname || !password || !confPassword) {
      openErrorMsgSnackbar('pages-login-fill-required');
      return;
    }

    if (password !== confPassword) {
      openErrorMsgSnackbar('pages-signup-pass-not-match');
      return;
    }

    HttpClient.post(
      'user/sendemailtoken',
      {
        email: state.email,
        password: '',
        token: '',
      },
      { withCredentials: true }
    )
      .then(response => {
        openSuccessSnackbar('pages-signup-sent-verify');
        setUiMode('verify-email');
      })
      .catch(error => {
        openErrorMsgSnackbar('pages-login-failed-generic');
        console.error(error);
      });
  };

  const doSignup = () => {
    setValidationMode(true);

    if (!email || !firstname || !lastname || !password || !confPassword) {
      openErrorMsgSnackbar('pages-login-fill-required');
      return;
    }

    if (password !== confPassword) {
      openErrorMsgSnackbar('pages-signup-pass-not-match');
      return;
    }
    HttpClient.post(
      'user/',
      {
        email: state.email,
        password: md5(state.password.trim()),
        userName: state.email,
        firstname: state.firstname,
        lastname: state.lastname,
        token: state.token,
        country: '',
        city: '',
        zipcode: 'state.zipcode',
        address: '',
      },
      { withCredentials: true }
    )
      .then(response => {
        MT.Dispatch(Config.MT_CHANNEL_REGISTERED, response.data);
      })
      .catch(error => {
        openErrorMsgSnackbar('pages-signup-failed');
        updateState({ ...state });
        console.error(error);
      });
  };
  setTimeout(function () {
    setCardAnimation('');
  }, 700);
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      <Header
        absolute
        color="transparent"
        brand="AI Lab"
        otherStyles={{ color: '#010924' }}
        rightLinks={<HeaderLinks history={props.history} />}
        {...rest}
      />
      <div
        className={classes.pageHeader}
        style={{
          background: 'center 20% url(' + brainBG + ')',
          backgroundSize: 'cover',
        }}
      >
        <div className={classes.container}>
          <GridContainer
            justify="center"
            style={{
              backgroundImage: 'url(' + lgBg + ')',
              backgroundSize: 'contain',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              // backgroundColor: 'rgba(0, 0, 0, 0.05)',
            }}
          >
            <GridItem xs={12} sm={6} md={4} className={classes.gridSignup}>
              <Card className={classes[cardAnimaton]}>
                <form className={classes.form}>
                  <CardHeader color="primary" className={classes.cardHeader}>
                    <h2>
                      <Trans>pages-signup-title</Trans>
                    </h2>
                    <div className={classes.socialLine}>
                      <Button
                        justIcon
                        href="#pablo"
                        target="_blank"
                        color="transparent"
                        onClick={e => e.preventDefault()}
                      >
                        <i className={'fab fa-twitter'} />
                      </Button>
                      <Button
                        justIcon
                        href="#pablo"
                        target="_blank"
                        color="transparent"
                        onClick={e => e.preventDefault()}
                      >
                        <i className={'fab fa-facebook'} />
                      </Button>
                      <Button
                        justIcon
                        href="#pablo"
                        target="_blank"
                        color="transparent"
                        onClick={e => e.preventDefault()}
                      >
                        <i className={'fab fa-google-plus-g'} />
                      </Button>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <div className={classes.layoutContainer}>
                      <div className={classes.columnRightContainer}>
                        <CustomInput
                          labelText={<Trans>common-email</Trans>}
                          id="email"
                          error={validationMode && !email}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            type: 'text',
                            endAdornment: (
                              <InputAdornment position="end">
                                <Email className={classes.inputIconsColor} />
                              </InputAdornment>
                            ),
                            onChange: event => {
                              state.email = event.target.value;
                              updateState({ ...state });
                            },
                          }}
                        />

                        {uiMode == 'signup' && (
                          <React.Fragment>
                            <CustomInput
                              labelText={<Trans>common-firstname</Trans>}
                              id="firstname"
                              error={validationMode && !firstname}
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                type: 'text',
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <People
                                      className={classes.inputIconsColor}
                                    />
                                  </InputAdornment>
                                ),
                                onChange: event => {
                                  state.firstname = event.target.value;
                                  updateState({ ...state });
                                },
                              }}
                            />
                            <CustomInput
                              labelText={<Trans>common-lastname</Trans>}
                              id="lastname"
                              error={validationMode && !lastname}
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                type: 'text',
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <People
                                      className={classes.inputIconsColor}
                                    />
                                  </InputAdornment>
                                ),
                                onChange: event => {
                                  state.lastname = event.target.value;
                                  updateState({ ...state });
                                },
                              }}
                            />
                            <CustomInput
                              labelText={<Trans>common-password</Trans>}
                              error={validationMode && !password}
                              id="pass"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                type: 'password',
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <LockIcon
                                      className={classes.inputIconsColor}
                                    />
                                  </InputAdornment>
                                ),
                                autoComplete: 'off',
                                onChange: event => {
                                  state.password = event.target.value;
                                  updateState({ ...state });
                                },
                              }}
                            />
                            <CustomInput
                              labelText={<Trans>common-confirm-password</Trans>}
                              error={validationMode && !confPassword}
                              id="pass"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                type: 'password',
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <LockIcon
                                      className={classes.inputIconsColor}
                                    />
                                  </InputAdornment>
                                ),
                                autoComplete: 'off',
                                onChange: event => {
                                  state.confPassword = event.target.value;
                                  updateState({ ...state });
                                },
                              }}
                            />
                          </React.Fragment>
                        )}
                        {uiMode != 'signup' && (
                          <CustomInput
                            labelText={<Trans>pages-signup-verify-token</Trans>}
                            id="token"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              autoComplete: 'off',
                              onChange: event => {
                                state.token = event.target.value;
                                updateState({ ...state });
                              },
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </CardBody>
                  <CardFooter className={classes.cardFooter}>
                    {uiMode != 'signup' && (
                      <React.Fragment>
                        <div
                          style={{
                            display: 'flex-box',
                            flexDirection: 'column',
                          }}
                        >
                          <div style={{ display: 'block' }}>
                            <Button
                              simple
                              color="primary"
                              size="lg"
                              onClick={doSignup}
                            >
                              <Trans>common-signup</Trans>
                            </Button>
                          </div>
                          <div style={{ display: 'block' }}>
                            <a
                              href="#"
                              style={{ color: '#00f', textDecoration: 'none' }}
                              onClick={sendVerify}
                            >
                              <Trans>pages-login-retry</Trans>
                            </a>
                          </div>
                        </div>
                      </React.Fragment>
                    )}

                    {uiMode == 'signup' && (
                      <>
                        <div
                          style={{
                            display: 'flex-box',
                            flexDirection: 'column',
                          }}
                        >
                          <div
                            style={{ display: 'block', textAlign: 'center' }}
                          >
                            <Button
                              simple
                              color="primary"
                              size="lg"
                              onClick={sendVerify}
                            >
                              <Trans>common-next</Trans>
                            </Button>
                          </div>
                          <div style={{ display: 'block' }}>
                            <a
                              href="#"
                              style={{
                                color: '#00f',
                                textDecoration: 'none',
                                marginLeft: '5x',
                              }}
                              onClick={() => props.history.push('/login')}
                            >
                              <Trans>pages-signup-already-member</Trans>
                            </a>
                          </div>
                        </div>
                      </>
                    )}
                  </CardFooter>
                </form>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
        <Snackbar
          open={errorMsg}
          autoHideDuration={6000}
          onClose={handleErrorMsgClose}
        >
          <ErrorAlert onClose={handleErrorMsgClose} severity="error">
            <Trans>{errorMsg}</Trans>
          </ErrorAlert>
        </Snackbar>
        <Snackbar
          open={successMsg}
          autoHideDuration={6000}
          onClose={handleSuccessMsgClose}
        >
          <Alert onClose={handleSuccessMsgClose} severity="success">
            <Trans>{successMsg}</Trans>
          </Alert>
        </Snackbar>
        <Footer />
      </div>
    </div>
  );
}
