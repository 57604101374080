import * as types from './constants';

const initialState = {
  list: [],
};

export default function Test(state = initialState, action) {
  switch (action.type) {
    case types.TEST:
      return {
        ...state,
        list: action.payload,
      };

    default:
      return state;
  }
}
