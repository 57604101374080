import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';
import { PersistGate } from 'redux-persist/integration/react';
import AppWrapper from './containers/AppWrapper';
import Lobby from './containers/Lobby';

const Root = ({ store, persistor }) => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router>
        <AppWrapper>
          <Lobby />
        </AppWrapper>
      </Router>
    </PersistGate>
  </Provider>
);
Root.propTypes = {
  store: PropTypes.objectOf(PropTypes.shape).isRequired,
  persistor: PropTypes.objectOf(PropTypes.shape).isRequired,
};

export default Root;
