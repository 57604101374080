import * as types from './constants';
import { getSampleData } from '../../api-calls/TestCall';

const test = () => (dispatch) => {
  getSampleData().then(r => {
    dispatch({
      type: types.TEST,
      payload: r,
    });
  }).catch(e => console.log(e));
};

export default {
  test
};
