import React from 'react';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  appWrapper: {
    width: '100%',
    height: '100%',
  },
});

const AppWrapper = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.appWrapper}>
      {children}
    </div>
  );
};

export default AppWrapper;
