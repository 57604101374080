const drawerWidth = 300;

const dashboard = theme => ({
  container: {
    width: '100%',
    height: '100%',
    overflowY: 'auto',
    background: '#0000001f',
  },
  contentContainer: {
    paddingTop: 76,
    display: 'flex',
  },
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerPaper: {
    position: 'fix',
    background: '#122b44',
    color: 'white',
    bottom: 0,
    paddingTop: 76,
    zIndex: 800,
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: 56,
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  listIcons: {
    color: '#e9eff7',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  pageContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#122b44',
    fontSize: 80,
  },
  dialogTitle: {
    background: theme.palette.primary.drawer,
    color: theme.palette.common.white,
  },
  responseWrapper: {
    minHeight: 100,
    border: '1px dashed grey',
    borderRadius: 10,
    padding: 10,
  },
  codeStyle: {
    background: '#f4f4f4',
    border: '1px solid #ddd',
    borderLeft: '3px solid #122b44',
    color: '#666',
    pageBreakInside: 'avoid',
    fontFamily: 'monospace',
    fontSize: 17,
    padding: 10,
    paddingLeft: 15,
  },
});

export default dashboard;
