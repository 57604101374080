import { title } from '../../../../jss/nextjs-material-kit.js';

const getStartedStyle = {
  section: {
    padding: '70px 0',
    textAlign: 'center',
  },
  title: {
    ...title,
    marginBottom: '1rem',
    marginTop: '30px',
    minHeight: '32px',
    textDecoration: 'none',
  },
  description: {
    color: '#555',
    fontSize: 16,
    fontFamily: 'unset',
  },
  planboxContainer: { margin: '10px 0', maxWidth: 350 },
  planBox: {
    display: 'display',
    flexDirection: 'column',
    border: '1px solid #0099ff',
    borderRadius: 10,
    overflow: 'hidden',
    width: '100%',
    // background: 'linear-gradient(180deg, white 50%, #1976d2 380%)',
  },
  plansContainer: {
    padding: [[20, 0]],
    dispay: 'flex',
    flexDirection: 'column',
  },
  starsContainer: {
    height: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  footerContainer: {
    height: 80,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  textContainer: {
    flex: 1,
    padding: 15,
    paddingTop: 30,
  },
  start: {
    color: '#fecf00',
    fontSize: 38,
  },
  planText: {
    color: '#2d3949',
    margin: [[20, 0]],
  },
  listIcon: {
    minWidth: 18,
  },
  bulletIcon: {
    fontSize: 8,
  },
  getStartButton: {
    borderRadius: 0,
    width: '100%',
    height: 75,
    boxShadow: 'none',
    marginBottom: -5,
  },
  imageStyle: {
    height: 30,
  },
};

export default getStartedStyle;
