import * as types from './constants';

const login = user => dispatch => {
  dispatch({
    type: types.LOGIN,
    payload: user,
  });
};

const logout = () => dispatch => {
  dispatch({
    type: types.LOGOUT,
  });
};

export default {
  login,
  logout,
};
