import React from 'react';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import theme from '../../helpers/Theme';
import AppWrapper from './AppWrapper';

const appTheme = createMuiTheme(theme);

const AppWrapperContainer = ({ history, children, ...props }) => (
  <MuiThemeProvider theme={appTheme}>
    <AppWrapper history={history} {...props}>
      {children}
    </AppWrapper>
  </MuiThemeProvider>
);

export default AppWrapperContainer;
