import React, { useEffect, useState } from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import DoneIcon from '@material-ui/icons/Lens';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

// @material-ui/icons
// core components
import GridContainer from '../../components/Grid/GridContainer.js';
import GridItem from '../../components/Grid/GridItem.js';

import styles from '../../assets/jss/nextjs-material-kit/pages/landingPageSections/getStartedStyle.js';
import HttpClient from '../../helpers/HttpClient';
import { Trans } from 'react-i18next';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(styles);

export default function GetStarted(props) {
  const { history } = props;
  const classes = useStyles();
  const [plans, setPlans] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const loadPlans = () => {
    HttpClient.get('plan/list')
      .then(response => {
        setPlans(response.data);
        setLoaded(true);
      })
      .catch(() => {
        console.error('load plans failed!');
      });
  };

  useEffect(() => {
    loadPlans();
  }, []);

  let plansHtml = null;
  if (loaded) {
    plansHtml = plans.map((plan, idx) => {
      return (
        <GridItem
          xs={12}
          sm={12}
          md={4}
          className={classes.planboxContainer}
          key={idx}
        >
          <ButtonBase className={classes.planBox}>
            <div className={classes.textContainer}>
              <Typography
                className={classes.planText}
                style={{ fontWeight: 600, opacity: 0.5 }}
                variant="caption"
              >
                {plan.name}
              </Typography>
              <Typography
                className={classes.planText}
                variant="h4"
                style={{ fontFamily: "'planText'" }}
              >
                {
                  <Trans values={{ cost: plan.cost }}>
                    pages-dashboard-tools-plan-change-cost
                  </Trans>
                }
              </Typography>
              <Typography
                className={classes.planText}
                style={{ opacity: 0.7 }}
                variant="subtitle2"
              >
                <List dence>
                  <ListItem>
                    <ListItemIcon className={classes.listIcon}>
                      <DoneIcon className={classes.bulletIcon} />
                    </ListItemIcon>
                    <ListItemText>
                      {
                        <Trans values={{ value: plan.calls }}>
                          plan-calls-desc
                        </Trans>
                      }
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemIcon className={classes.listIcon}>
                      <DoneIcon className={classes.bulletIcon} />
                    </ListItemIcon>
                    <ListItemText>
                      {<Trans>plan-support-desc</Trans>}
                      {plan.hasSupport ? (
                        <CheckIcon
                          color="primary"
                          style={{ margin: '0px 0 -6px 2px' }}
                        />
                      ) : (
                        <CloseIcon
                          color="error"
                          style={{ margin: '0px 0 -6px 2px' }}
                        />
                      )}
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemIcon className={classes.listIcon}>
                      <DoneIcon className={classes.bulletIcon} />
                    </ListItemIcon>
                    <ListItemText>
                      {<Trans>plan-integration-desc</Trans>}
                      {plan.hasIntegration ? (
                        <CheckIcon
                          color="primary"
                          style={{ margin: '0px 0 -6px 2px' }}
                        />
                      ) : (
                        <CloseIcon
                          color="error"
                          style={{ margin: '0px 0 -6px 2px' }}
                        />
                      )}
                    </ListItemText>
                  </ListItem>
                </List>
              </Typography>
            </div>
            <div className={classes.footerContainer}>
              <Button
                variant="contained"
                color="primary"
                className={classes.getStartButton}
                onClick={() => {
                  history.push(`/plan/${plan.id}`);
                }}
              >
                Get Started
              </Button>
            </div>
          </ButtonBase>
        </GridItem>
      );
    });
  }

  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>
            <Trans>home-site-plans-section-title</Trans>
          </h2>
          <h5 className={classes.description}>
            <Trans>home-site-plans-section-text</Trans>
          </h5>
        </GridItem>
      </GridContainer>
      <div className={classes.plansContainer}>
        <GridContainer justify="center">{plansHtml}</GridContainer>
      </div>
    </div>
  );
}
