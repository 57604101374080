import React from 'react';
import { Switch, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import Home from '../../pages/Home';
import SignUp from '../../pages/SignUp';
import LandingPage from '../../pages/LandingPage';
import Login from '../../pages/Login';
import Status from '../../pages/Status';
import PlanDetail from '../../pages/PlanDetail';
import Dashboard from '../../pages/Dashboard';
import Product from '../../pages/ProductDetail';

const Lobby = () => {
  return (
    <>
      <Switch>
        <Route exact path="/" component={LandingPage} />
        <Route exact path="/home" component={Home} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/signup" component={SignUp} />
        <Route exact path="/plan/:id" component={PlanDetail} />
        <Route exact path="/status" component={Status} />
        <Route exact path="/dashboard" component={Dashboard} />
        <Route exact path="/product/:id" component={Product} />
      </Switch>
    </>
  );
};

export default Lobby;
