import React, {useEffect, useState} from 'react';
// @material-ui/core components
import {makeStyles, withStyles} from '@material-ui/core/styles';
import styles from '../../assets/jss/nextjs-material-kit/pages/dashboard.js';
import HttpClient from '../../helpers/HttpClient';
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import {Trans} from "react-i18next";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {Bar} from 'react-chartjs-2';
import moment from 'moment';
import i18n from "i18next";

const useStyles = makeStyles(styles);

export default function UsageReport() {
    const classes = useStyles();
    const [plans, setplans] = useState([]);
    const [myplan, setMyPlan] = useState({});
    const [loaded, setLoaded] = useState(false);

    const loadPlans = () => {
        HttpClient
            .get('plan/list', {withCredentials: true}, true)
            .then(response => {
                setplans(response.data);
                HttpClient
                    .get('plan/myplanusage', {withCredentials: true}, true)
                    .then(response => {
                        setMyPlan(response.data);
                        setLoaded(true);
                    })
                    .catch(() => {
                        console.error('load my plan failed!');
                    });
                setLoaded(true);
            })
            .catch(() => {
                console.error('load plans failed!');
            });
    }

    useEffect(() => {
        loadPlans();
    }, []);


    let usageList;
    if (!loaded) {
        usageList = <Trans>common-loading</Trans>;
    } else if (loaded) {
        if (myplan.usages && myplan.usages.length > 0) {
            const data = {};
            data.labels = [];
            data.datasets = [];
            const dataset = {};
            dataset.label = i18n.t("pages-dashboard-tools-api-usage-chart-title");
            dataset.data = [];
            dataset.backgroundColor = "#5ab773";
            const availableData = {};
            myplan.usages.forEach((row, index) => {
                const d = row.year + "-" + (row.month < 10 ? "0" + row.month : row.month) + "-" + (row.day < 10 ? "0" + row.day : row.day);
                availableData[d] = row.calls;
            });

            const lastMonth = moment().subtract(1, 'months');
            while (moment().isAfter(lastMonth)) {
                data.labels.push(lastMonth.format("YYYY-MM-DD"));
                dataset.data.push(availableData[lastMonth.format("YYYY-MM-DD")]??0)
                lastMonth.add(1, 'day');
            }
            data.datasets[0] = dataset;

            usageList =
                <>
                    <Bar
                        data={data}
                        width={800}
                        height={300}
                        options={{maintainAspectRatio: true}}
                    />
                </>;
        } else {
            usageList = <Trans>pages-dashboard-tools-api-usage-empty</Trans>;
        }

    }

    return (<Container maxWidth="lg">
        <Typography component="div">
            <Typography variant="h3" style={{marginTop: '10px'}} gutterBottom>
                <Trans>pages-dashboard-tools-api-usage</Trans>
            </Typography>

            <div style={{display: "table", minWidth: 500}}>
                {usageList}
            </div>


        </Typography>

    </Container>);
}
