import { container, title } from '../../nextjs-material-kit.js';

const landingPageStyle = theme => ({
  container: {
    zIndex: '12',
    color: '#FFFFFF',
    ...container,
  },
  title: {
    // ...title,
    color: '#1a1227',
    fontSize: 210,
    marginLeft: 70,
    fontFamily: "'Goldman', sans-serif",
    fontWeight: 500,
    lineHeight: '0px',
    [theme.breakpoints.down('1450')]: {
      marginLeft: 35,
      fontSize: 160,
    },
    [theme.breakpoints.down('650')]: {
      fontSize: 120,
      lineHeight: '90px',
      width: 'min-content',
    },
  },
  title24: {
    marginBottom: 30,
    fontSize: 45,
    color: '#f2bc1b',
    marginLeft: 2,
    [theme.breakpoints.down('650')]: {
      marginBottom: 55,
      fontSize: 38,
    },
  },
  subtitle: {
    paddingLeft: 72,
    width: 680,
    fontSize: 17,
    marginTop: -38,
    [theme.breakpoints.down('1450')]: {
      paddingLeft: 40,
      fontSize: 15,
      marginTop: -16,
    },
    [theme.breakpoints.down('650')]: {
      width: 280,
      marginTop: -50,
      lineHeight: '22px',
    },
  },
  main: {
    background: '#FFFFFF',
    position: 'relative',
    zIndex: '3',
  },
  mainRaised: {
    margin: '-60px 30px 0px',
    borderRadius: '30px',
    boxShadow:
      '0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)',
    [theme.breakpoints.down('xs')]: {
      margin: 0,
    },
  },
  labLogo: {
    width: 22,
    marginLeft: 6,
    marginBottom: -5,
  },
});

export default landingPageStyle;
