import { combineReducers } from 'redux';
import Test from '../Test/reducers';
import User from '../User/reducers';

const appReducer = combineReducers({
  Test,
  User,
});

const RootReducer = (state, action) => {
  const newState = { ...state };
  return appReducer(newState, action);
};

export default RootReducer;
