import React, { useState, useEffect } from 'react';
import HttpClient from '../../helpers/HttpClient';
import MT from '../../helpers/MT';
import StatusItem from './StatusItem';
import Config from '../../Config';
import './StatusContainer.css';

const StatusContainer = () => {
  const [services, setServices] = useState();

  const renderService = rawServices => {
    let services = rawServices.map(srvarr => {
      return {
        service: srvarr[0].serviceId,
        status: srvarr[0].instanceInfo.status,
        updated: new Date(srvarr[0].instanceInfo.lastUpdatedTimestamp),
      };
    });
    setServices(services);
  };

  useEffect(() => {
    MT.Subscribe(Config.MT_CHANNEL_SERVICES_LOADED, renderService);
    refreshServices();
    window.setInterval(refreshServices, Config.STATUS_REFRESH_INTERVAL_MS);
  }, []);

  const refreshServices = () => {
    HttpClient
      .get( 'microservice/status')
      .then(response => {
        MT.Dispatch(Config.MT_CHANNEL_SERVICES_LOADED, response.data);
      })
      .catch(error => {
        console.log(error);
      });
  };

  let srvcs = services
    ? services.map(s => (
        <StatusItem
          key={s.service}
          service={s.service}
          status={s.status}
          updated={s.updated.toJSON()}
        />
      ))
    : [];
  return (
    <div className="StatusContainer">
      {srvcs.length > 0 ? srvcs : <span>loading ...</span>}
    </div>
  );
};

export default StatusContainer;
