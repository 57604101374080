import React, {useState} from 'react';
// @material-ui/core components
import {makeStyles} from '@material-ui/core/styles';
import {useSelector} from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Edit from '@material-ui/icons/Edit';
import Save from '@material-ui/icons/Save';
import Person from '@material-ui/icons/Person';
import Pass from '@material-ui/icons/VpnKey';
import PhoneIcon from '@material-ui/icons/PhoneOutlined';
import MailIcon from '@material-ui/icons/MailOutline';
import LocationIcon from '@material-ui/icons/LocationOnOutlined';
import Typography from '@material-ui/core/Typography';
import ToggleTextEdit from "../../components/ToggleTextEdit/ToggleTextEdit";
import HttpClient from '../../helpers/HttpClient';
import * as md5 from "md5";
import {useDispatch} from 'react-redux';
import UserActions from "../../store/User/actions";
import {Trans} from "react-i18next";
import Card from "../../components/Card/Card";


const hardcodeUser = {
    address: 'Manzarie',
    city: 'Rasht',
    country: 'Iran',
    email: 'erfan.samieyan95@gmail.com',
    firstname: 'Erfan',
    lastname: 'Samieyan',
    mobile: '09358671485',
    userName: 'erfooon',
    zipcode: '12345',
};

const useStyles = makeStyles(() => ({
    pageContainer: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#122b44',
    },
    container: {
        padding: '20px 50px 0 90px',
        width: '50%',
        maxWidth: 800,
        position: 'relative',
    },
    nameContainer: {
        display: 'flex',
        margin: '0 0 40px -50px',
    },
    editButton: {
        position: 'absolute',
        bottom: 25,
        right: 25,
    },
    nameAndCountry: {
        display: 'flex',
        flexDirection: 'column',
        margin: '10px 0 0 20px',
    },
    avatar: {
        height: 100,
        width: 100,
    },
    nameStyle: {
        fontSize: 20,
        fontWeight: 'bold',
    },
    eachRow: {
        borderBottom: '1px solid #0000001f',
        padding: [[20, 0]],
        position: 'relative',
    },
    icon: {
        position: 'absolute',
        left: -50,
    },
    phoneNo: {
        fontWeight: 'bold',
        fontSize: 16,
    },
    colorBlue: {
        fontWeight: 'bold',
    },
    caption: {
        fontSize: 14,
        color: '#0000008a',
        paddingTop: 3,
    },
    mapImage: {
        marginTop: 20,
        width: 280,
        border: '1px solid #0000001f',
        borderRadius: 5,
        maxWidth: '100%',
    },
    alertBox: {
        padding: 10,
        fontWeight: "bold",
        marginBottom: 5
    }
}));

const ContactInfo = () => {
    let [editMode, setEditMode] = useState(false);
    const dispatch = useDispatch();

    const classes = useStyles();
    const loggedInUser = useSelector(state => state.User.user);
    const user = loggedInUser || hardcodeUser;
    let [firstname, set_firstname] = useState(user.firstname);
    let [email, set_email] = useState(user.email);
    let [lastname, set_lastname] = useState(user.lastname);
    let [userName, set_userName] = useState(user.userName);
    let [country, set_country] = useState(user.country);
    let [city, set_city] = useState(user.city);
    let [address, set_address] = useState(user.address);
    let [mobile, set_mobile] = useState(user.mobile);
    let [zipcode, set_zipcode] = useState(user.zipcode);
    let [password, set_password] = useState("");
    let [saveError, set_saveError] = useState(false);
    let [saveSuccess, set_saveSuccess] = useState(false);
    const toggleEditMode = () => {
        set_saveSuccess(false);
        set_saveError(false);
        setEditMode(!editMode);
    }
    const updateInfo = () => {

        set_saveSuccess(false);
        set_saveError(false);

        user.firstname = firstname;
        user.email = email;
        user.lastname = lastname;
        user.userName = userName;
        user.country = country;
        user.city = city;
        user.address = address;
        user.mobile = mobile;
        user.zipcode = zipcode;
        if (password.trim().length > 0 && password != "") {
            user.password = md5(password);
        }

        HttpClient
            .post( 'user/save/' + user.id, user, {withCredentials: true}, true)
            .then(response => {
                toggleEditMode();
                set_saveSuccess(true);
                set_saveError(false);
                UserActions.login(user)(dispatch);
            })
            .catch(error => {
                set_saveSuccess(false);
                set_saveError(true);
                console.error('update profile failed!', error);
            });
    }
    if (!user) return null;
    return (
        <div className={classes.pageContainer}>
            <Paper  className={classes.container} style={{marginTop: 20}} >

                <div className={classes.nameContainer}>
                    <Avatar className={classes.avatar}/>
                    <div className={classes.nameAndCountry}>
                        <Typography className={classes.nameStyle}>
                            <ToggleTextEdit editMode={editMode} value={user.firstname}
                                            onChange={(event) => set_firstname(event.target.value)}/> <ToggleTextEdit
                            editMode={editMode} value={user.lastname}
                            onChange={(event) => set_lastname(event.target.value)}/>
                        </Typography>

                    </div>
                </div>
                <div className={classes.eachRow}>
                    <PhoneIcon color="primary" className={classes.icon}/>
                    <Typography className={classes.caption}>Phone Number</Typography>
                    <Typography color="primary" className={classes.phoneNo}>
                        <ToggleTextEdit editMode={editMode} value={user.mobile}
                                        onChange={(event) => set_mobile(event.target.value)}/>
                    </Typography>
                </div>
                <div className={classes.eachRow}>
                    <MailIcon style={{opacity: 0.54}} className={classes.icon}/>
                    <Typography className={classes.caption}>Email Address</Typography>
                    <Typography className={classes.email}><ToggleTextEdit editMode={editMode} value={user.email}
                                                                          onChange={(event) => set_email(event.target.value)}/></Typography>
                </div>
                <div className={classes.eachRow}>
                    <Person style={{opacity: 0.54}} className={classes.icon}/>
                    <Typography className={classes.caption}>Username</Typography>
                    <Typography className={classes.email}><ToggleTextEdit editMode={editMode} value={user.userName}
                                                                          onChange={(event) => set_userName(event.target.value)}/></Typography>
                </div>
                <div className={classes.eachRow}>
                    <Pass style={{opacity: 0.54}} className={classes.icon}/>
                    <Typography className={classes.caption}>Password</Typography>
                    {!editMode && <span>*******************</span>}
                    <Typography className={classes.email}><ToggleTextEdit type="password" editMode={editMode} value=""
                                                                          onChange={(event) => set_password(event.target.value)}/></Typography>
                    {editMode &&
                    <span style={{color: "#b1b1b1", fontSize: "smaller"}}>Leave it empty if you do not want to change it!</span>}
                </div>
                <div className={classes.eachRow} style={{border: 'none'}}>
                    <LocationIcon style={{opacity: 0.54}} className={classes.icon}/>
                    <Typography className={classes.caption}>Address</Typography>
                    <Typography className={classes.email}>
                        <ToggleTextEdit editMode={editMode} value={user.address}
                                        onChange={(event) => set_address(event.target.value)}/>
                    </Typography>
                    <Typography className={classes.countryStyle}>
                        <ToggleTextEdit editMode={editMode} value={user.city}
                                        onChange={(event) => set_city(event.target.value)}/>
                        <span>&nbsp;</span>
                        <ToggleTextEdit editMode={editMode} value={user.zipcode}
                                        onChange={(event) => set_zipcode(event.target.value)}/>
                    </Typography>
                    <Typography className={classes.countryStyle}>
                        <ToggleTextEdit editMode={editMode} value={user.country}
                                        onChange={(event) => set_country(event.target.value)}/>
                    </Typography>

                </div>
                <div className={classes.eachRow} style={{border: 'none'}}>
                    {!editMode && <Button
                        variant="contained"
                        color="primary"
                        onClick={toggleEditMode}
                        endIcon={<Edit/>}
                    >
                        Edit Profile
                    </Button>}
                    {editMode && <Button
                        variant="contained"
                        color="primary"
                        endIcon={<Save/>}
                        onClick={updateInfo}
                    >
                        Save Changes
                    </Button>}
                </div>
                {saveError && <div className={classes.alertBox} style={{backgroundColor: "#fcb8b8"}}>
                    Unable to Save you changes.
                </div>}
                {saveSuccess && <div className={classes.alertBox} style={{backgroundColor: "#a3cba3"}}>
                    Your changes saved successfully
                </div>}

            </Paper>
        </div>
    );
};

export default ContactInfo;
