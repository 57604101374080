import React, { useState, useEffect } from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useDispatch } from 'react-redux';
// @material-ui/icons
import Email from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/LockOutlined';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { Trans } from 'react-i18next';

// core components
import Header from '../../components/Header/Header.js';
import HeaderLinks from '../../components/Header/HeaderLinks.js';
import Footer from '../../components/Footer/Footer.js';
import GridContainer from '../../components/Grid/GridContainer.js';
import GridItem from '../../components/Grid/GridItem.js';
import Button from '../../components/CustomButtons/Button.js';
import Card from '../../components/Card/Card.js';
import CardBody from '../../components/Card/CardBody.js';
import CardHeader from '../../components/Card/CardHeader.js';
import CardFooter from '../../components/Card/CardFooter.js';
import CustomInput from '../../components/CustomInput/CustomInput.js';
import UserActions from '../../store/User/actions';
import styles from '../../assets/jss/nextjs-material-kit/pages/loginPage.js';

import image from '../../assets/img/login-bg.jpg';
import brainBG from '../../assets/img/bg-light.png';
import illust from '../../assets/img/AI-mind.svg';
import Config from '../../Config';
import HttpClient from '../../helpers/HttpClient';
import MT from '../../helpers/MT';
import * as md5 from 'md5';
import { Alert } from '@material-ui/lab';
import lgBg from '../../assets/img/login-background.svg';

const useStyles = makeStyles(styles);

export default function Login(props) {
  const [cardAnimaton, setCardAnimation] = React.useState('cardHidden');
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [pass, setPass] = useState('');
  const [newPass, setNewPass] = useState('');
  const [confirmPass, setConfirmPass] = useState('');
  const [resetToken, setResetToken] = useState('');
  const [uiMode, setUiMode] = useState('login');

  const [errorMsg, setErrorMsg] = React.useState('');
  const [successMsg, setSuccessMsg] = React.useState('');
  const [validationMode, setValidationMode] = React.useState(false);

  const openErrorSnackbar = msg => {
    setErrorMsg(msg);
  };

  const openSuccessSnackbar = msg => {
    setSuccessMsg(msg);
  };

  const handleErrorMsgClose = () => {
    setErrorMsg('');
  };
  const handleSuccessMsgClose = () => {
    setSuccessMsg('');
  };

  useEffect(() => {
    MT.Subscribe(Config.MT_CHANNEL_LOGGED_IN, user => {
      UserActions.login(user)(dispatch);
      props.history.push('/dashboard');
    });
  }, []);
  const sendResetToken = () => {
    setValidationMode(true);
    if (!email) {
      openErrorSnackbar('pages-login-fill-required');
      return;
    }
    HttpClient.post(
      'user/sendtoken',
      {
        email: email,
        password: '',
        token: '',
      },
      { withCredentials: true }
    )
      .then(response => {
        openSuccessSnackbar('pages-login-reset-token-sent');
        setUiMode('reset-pass');
      })
      .catch(error => {
        openErrorSnackbar('pages-login-failed-generic');
        console.error(error);
      });
  };
  const doLogin = () => {
    setValidationMode(true);
    if (!email || !pass) {
      openErrorSnackbar('pages-login-fill-required');
      return;
    }
    HttpClient.post(
      'user/login',
      {
        email: email,
        password: md5(pass.trim()),
      },
      { withCredentials: true }
    )
      .then(response => {
        MT.Dispatch(Config.MT_CHANNEL_LOGGED_IN, response.data);
      })
      .catch(error => {
        openErrorSnackbar('pages-login-failed');
      });
  };

  const changePass = () => {
    setValidationMode(true);
    if (!email || !newPass || !resetToken) {
      openErrorSnackbar('pages-login-fill-required');
      return;
    }
    if (newPass != confirmPass) {
      openErrorSnackbar('pages-signup-pass-not-match');
      return;
    }
    HttpClient.post(
      'user/resetpass',
      {
        email: email,
        password: md5(newPass.trim()),
        token: resetToken,
      },
      { withCredentials: true }
    )
      .then(response => {
        openSuccessSnackbar('pages-login-reset-password-changed');
        setUiMode('login');
      })
      .catch(error => {
        openErrorSnackbar('pages-login-failed-generic');
        console.error(error);
      });
  };
  const showForgetPassUiMode = () => {
    setUiMode('forget-pass');
  };
  setTimeout(function () {
    setCardAnimation('');
  }, 700);
  const classes = useStyles();
  const { ...rest } = props;

  function ErrorAlert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  return (
    <div>
      <Header
        absolute
        color="transparent"
        brand="AI Lab"
        otherStyles={{ color: '#010924' }}
        rightLinks={<HeaderLinks history={props.history} />}
        {...rest}
      />
      <div
        className={classes.pageHeader}
        style={{
          background: 'center 20% url(' + brainBG + ')',
          backgroundSize: 'cover',
        }}
      >
        <div className={classes.container}>
          <GridContainer
            justify="center"
            style={{
              backgroundImage: 'url(' + lgBg + ')',
              backgroundSize: 'contain',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              // backgroundColor: 'rgba(0, 0, 0, 0.05)',
            }}
          >
            <GridItem xs={12} sm={6} md={4}>
              <Card className={classes[cardAnimaton]}>
                <form className={classes.form}>
                  <CardHeader color="primary" className={classes.cardHeader}>
                    <h2>
                      {uiMode == 'login' && <Trans>pages-login-title</Trans>}
                      {uiMode != 'login' && (
                        <Trans>pages-login-reset-title</Trans>
                      )}
                    </h2>
                    <div className={classes.socialLine}>
                      <Button
                        justIcon
                        href="#pablo"
                        target="_blank"
                        color="transparent"
                        onClick={e => e.preventDefault()}
                      >
                        <i className={'fab fa-twitter'} />
                      </Button>
                      <Button
                        justIcon
                        href="#pablo"
                        target="_blank"
                        color="transparent"
                        onClick={e => e.preventDefault()}
                      >
                        <i className={'fab fa-facebook'} />
                      </Button>
                      <Button
                        justIcon
                        href="#pablo"
                        target="_blank"
                        color="transparent"
                        onClick={e => e.preventDefault()}
                      >
                        <i className={'fab fa-google-plus-g'} />
                      </Button>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <CustomInput
                      labelText={<Trans>common-email</Trans>}
                      id="email"
                      error={validationMode && !email}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: 'text',
                        endAdornment: (
                          <InputAdornment position="end">
                            <Email className={classes.inputIconsColor} />
                          </InputAdornment>
                        ),
                        onChange: event => setEmail(event.target.value),
                      }}
                    />
                    {uiMode == 'forget-pass' && (
                      <div style={{ color: '#aaa' }}>
                        <Trans>pages-login-reset-hint</Trans>
                      </div>
                    )}
                    {uiMode == 'login' && (
                      <CustomInput
                        labelText={<Trans>common-password</Trans>}
                        id="pass"
                        error={validationMode && !pass}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: 'password',
                          endAdornment: (
                            <InputAdornment position="end">
                              <LockIcon className={classes.inputIconsColor} />
                            </InputAdornment>
                          ),
                          autoComplete: 'off',
                          onChange: event => setPass(event.target.value),
                        }}
                      />
                    )}
                    {uiMode == 'reset-pass' && (
                      <React.Fragment>
                        <CustomInput
                          labelText={<Trans>pages-login-reset-token</Trans>}
                          id="token"
                          error={validationMode && !resetToken}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            autoComplete: 'off',
                            onChange: event =>
                              setResetToken(event.target.value),
                          }}
                        />
                        <CustomInput
                          labelText={<Trans>common-password</Trans>}
                          id="newpass"
                          error={validationMode && !newPass}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            type: 'password',
                            endAdornment: (
                              <InputAdornment position="end">
                                <LockIcon className={classes.inputIconsColor} />
                              </InputAdornment>
                            ),
                            autoComplete: 'off',
                            onChange: event => setNewPass(event.target.value),
                          }}
                        />
                        <CustomInput
                          labelText={<Trans>common-confirm-password</Trans>}
                          id="confirmpass"
                          error={validationMode && !confirmPass}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            type: 'password',
                            endAdornment: (
                              <InputAdornment position="end">
                                <LockIcon className={classes.inputIconsColor} />
                              </InputAdornment>
                            ),
                            autoComplete: 'off',
                            onChange: event =>
                              setConfirmPass(event.target.value),
                          }}
                        />
                      </React.Fragment>
                    )}
                  </CardBody>
                  <CardFooter className={classes.cardFooter}>
                    {uiMode == 'login' && (
                      <React.Fragment>
                        <div
                          style={{
                            display: 'flex-box',
                            flexDirection: 'column',
                          }}
                        >
                          <div
                            style={{ display: 'block', textAlign: 'center' }}
                          >
                            <Button
                              simple
                              color="primary"
                              size="lg"
                              onClick={doLogin}
                            >
                              <Trans>common-login</Trans>
                            </Button>
                          </div>
                          <div style={{ display: 'block' }}>
                            <a
                              href="#"
                              style={{ color: '#00f', textDecoration: 'none' }}
                              onClick={showForgetPassUiMode}
                            >
                              <Trans>pages-login-reset</Trans>
                            </a>
                            <a
                              href="#"
                              style={{
                                color: '#00f',
                                textDecoration: 'none',
                                marginLeft: '5x',
                              }}
                              onClick={() => props.history.push('/signup')}
                            >
                              &nbsp;&nbsp;&nbsp;
                              <Trans>pages-login-not-member</Trans>
                            </a>
                          </div>
                        </div>
                      </React.Fragment>
                    )}

                    {uiMode == 'forget-pass' && (
                      <Button
                        simple
                        color="primary"
                        size="lg"
                        onClick={sendResetToken}
                      >
                        <Trans>pages-login-reset-send-token</Trans>
                      </Button>
                    )}
                    {uiMode == 'reset-pass' && (
                      <React.Fragment>
                        <div
                          style={{
                            display: 'flex-box',
                            flexDirection: 'column',
                          }}
                        >
                          <div style={{ display: 'block' }}>
                            <Button
                              simple
                              color="primary"
                              size="lg"
                              onClick={changePass}
                            >
                              <Trans>
                                pages-dashboard-tools-password-change
                              </Trans>
                            </Button>
                          </div>
                          <div style={{ display: 'block' }}>
                            <a
                              href="#"
                              style={{ color: '#00f', textDecoration: 'none' }}
                              onClick={() => setUiMode('forget-pass')}
                            >
                              <Trans>pages-login-retry</Trans>
                            </a>
                          </div>
                        </div>
                      </React.Fragment>
                    )}
                  </CardFooter>
                </form>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
        <Snackbar
          open={errorMsg}
          autoHideDuration={6000}
          onClose={handleErrorMsgClose}
        >
          <Alert onClose={handleErrorMsgClose} severity="error">
            <Trans>{errorMsg}</Trans>
          </Alert>
        </Snackbar>
        <Snackbar
          open={successMsg}
          autoHideDuration={6000}
          onClose={handleSuccessMsgClose}
        >
          <Alert onClose={handleSuccessMsgClose} severity="success">
            <Trans>{successMsg}</Trans>
          </Alert>
        </Snackbar>
        <Footer />
      </div>
    </div>
  );
}
