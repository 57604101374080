import React, { useEffect, useState } from 'react';
// @material-ui/core components
import { withStyles, makeStyles } from '@material-ui/core/styles';
import styles from '../../assets/jss/nextjs-material-kit/pages/dashboard.js';
import HttpClient from '../../helpers/HttpClient';
import { Trans } from 'react-i18next';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import { JsonEditor as Editor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';
const StyledTableCell = withStyles(theme => ({
  head: {
    color: theme.palette.common.white,
    background: theme.palette.primary.drawer,
    height: 40,
    fontSize: 16,
    fontWeight: 'bold',
  },
  cell: {
    '&:nth-of-type(odd)': {
      background: 'grey',
      fontSize: 14,
    },
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles(styles);

export default function TestAPI() {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const [APIs, setAPIs] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [currentApiIndex, setCurrentApiIndex] = useState(-1);
  const [APIResponse, setAPIResponse] = useState('');
  const [error, setError] = useState('');
  const [msg, setMsg] = useState('');
  const [inputJSON, setInputJSON] = useState({ name: 'Erfan' });
  const showTestUI = (event, index) => {
    setCurrentApiIndex(index);
    setInputJSON(JSON.parse(APIs[index].sampleInput));
    setAPIResponse('');
    setOpen(true);
  };

  useEffect(() => {
    HttpClient.get('api/list')
      .then(response => {
        setAPIs(response.data);
        setLoaded(true);
      })
      .catch(() => {
        setError('pages-login-failed');
        setMsg('');
        console.error('load APIs failed!');
      });
  }, []);

  let currentAPI = null;
  let currentAPIDesc = 'this is doc about service\n it is very good';
  let currentAPIResponse = '';

  if (loaded && currentApiIndex !== -1) {
    currentAPI = APIs[currentApiIndex];
    currentAPIDesc = (
      <Trans>
        api-desc-{currentAPI.apiVersion + '-' + currentAPI.publicEndpoint}
      </Trans>
    );
  }

  const handleSend = event => {
    setAPIResponse('loading...');
    const url =
      'api/' + currentAPI.apiVersion + '/' + currentAPI.publicEndpoint;
    const data = inputJSON;
    HttpClient.post(url, data, { withCredentials: true })
      .then(response => {
        setAPIResponse(JSON.stringify(response.data, null, 2));
      })
      .catch(error => {
        setAPIResponse('API call failed ...');
        console.error('load APIs failed!', error);
      });
  };
  let list = null;
  if (loaded) {
    list = (
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell component="th">
                <Trans>pages-api-test-version</Trans>
              </StyledTableCell>
              <StyledTableCell component="th">
                <Trans>pages-api-test-endpoint</Trans>
              </StyledTableCell>
              <StyledTableCell component="th">
                <Trans>common-description</Trans>
              </StyledTableCell>
              <StyledTableCell component="th">
                <Trans>pages-api-test-test</Trans>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {APIs.map((row, index) => (
              <StyledTableRow key={row.apiVersion + '-' + row.publicEndpoint}>
                <TableCell>{row.apiVersion}</TableCell>
                <TableCell>{row.publicEndpoint}</TableCell>
                <TableCell>
                  <Trans>
                    api-desc-{row.apiVersion + '-' + row.publicEndpoint}
                  </Trans>
                </TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="primary"
                    data-index={index}
                    onClick={event => {
                      showTestUI(event, index);
                    }}
                  >
                    <Trans>pages-api-test-testit</Trans>
                  </Button>
                </TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  } else {
    list = (
      <div>
        <Trans>common-loading</Trans>
      </div>
    );
  }

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Container maxWidth="lg">
      <Typography component="div">
        <Typography variant="h3" style={{ marginTop: '10px' }} gutterBottom>
          <Trans>pages-dashboard-tools-api-test</Trans>
        </Typography>
        {list}
      </Typography>
      <Dialog
        maxWidth={'lg'}
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle className={classes.dialogTitle} id="form-dialog-title">
          {currentAPI?.apiVersion + ' / ' + currentAPI?.publicEndpoint}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{currentAPIDesc}</DialogContentText>
          <div>
            <h4>
              <Trans>pages-api-test-input</Trans>
            </h4>
            <Editor
              value={inputJSON}
              onChange={setInputJSON}
              htmlElementProps={{ style: { height: 200, width: 600 } }}
            />

            <h4>
              <Trans>pages-api-test-response</Trans>
            </h4>
            <div className={classes.responseWrapper}>{APIResponse}</div>
          </div>
        </DialogContent>
        <DialogActions style={{ padding: '20px 25px' }}>
          <Button onClick={handleClose} color="primary">
            <Trans>common-close</Trans>
          </Button>
          <Button onClick={handleSend} color="primary" variant="contained">
            <Trans>common-send</Trans>
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
