import React from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import styles from '../../assets/jss/nextjs-material-kit/pages/dashboard.js';
const useStyles = makeStyles(styles);

export default function TestAPI() {
  const classes = useStyles();
  return <div className={classes.pageContainer}>Plugins</div>;
}
