import axios from 'axios';
import Config from '../Config';



const get = (path, configs, redirectInCaseOfAuthorizationError = false) => {
  return axios
    .get(Config.GATEWAY_BASE_URL + path, configs)
    .then(result => {
      return result;
    })
    .catch(error => {
      console.log("Failed", redirectInCaseOfAuthorizationError, error.response.status  );
      if(redirectInCaseOfAuthorizationError && ( error.response.status == 401 || error.response.status == 403 )){
        console.log("Redirect to login ...");
        localStorage.clear();
        window.location = "/login";
      }
      return Promise.reject(error);
    });
};
const post = (path, data, configs, redirectInCaseOfAuthorizationError = false) => {
  return axios
    .post(Config.GATEWAY_BASE_URL + path, data, configs)
    .then(result => {
      return result;
    })
    .catch((error) => {
      console.log("Failed", redirectInCaseOfAuthorizationError, error.response.status  );
      if(redirectInCaseOfAuthorizationError && ( error.response.status === 401 || error.response.status === 403 )){
        console.log("Redirect to login ...");
        localStorage.clear();
        window.location = "/login";
      }
      return Promise.reject(error);
    });
};

const httpClient = {
  get,
  post,
};

export default httpClient;
