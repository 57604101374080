import React, { useState } from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Icon from '@material-ui/core/Icon';
// @material-ui/icons

import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';

// core components
import Footer from '../../components/Footer/Footer.js';
import Header from '../../components/Header/Header.js';
import HeaderLinks from '../../components/Header/HeaderLinks.js';
import styles from '../../assets/jss/nextjs-material-kit/pages/dashboard.js';

import DashboardDrawer from './DashboardDrawer';
import APIDoc from './APIDoc';
import Plugins from './Plugins';
import TestAPI from './TestAPI';
import ManageAPI from './ManageAPI';
import ChangePlan from './ChangePlan';
import EditProfile from './EditProfile';
import UsageReport from './UsageReport';

const useStyles = makeStyles(styles);

export default function Dashboard(props) {
  const user = useSelector(state => state.User.user);
  const [page, setPage] = useState(0);
  const classes = useStyles();
  const { ...rest } = props;

  if (!user) {
    props.history.push('/login');
    return null;
  }

  return (
    <div className={classes.container}>
      <Header
        // color="transparent"
        brand="AI Lab"
        rightLinks={<HeaderLinks history={props.history} />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: 'white',
        }}
        {...rest}
      />
      <div className={classes.contentContainer}>
        <DashboardDrawer changePage={p => setPage(p)} />
        {page === 0 && <ManageAPI />}
        {page === 1 && <TestAPI />}
        {page === 2 && <UsageReport />}
        {page === 3 && <APIDoc />}
        {page === 4 && <Plugins />}
        {page === 5 && <ChangePlan />}
        {page === 6 && <EditProfile />}
      </div>
    </div>
  );
}
