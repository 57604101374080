import React from 'react';
import ReactDOM from 'react-dom';
import { history, store, persistor } from './helpers';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import i18n from './i18n';

i18n.init().then(
    () => ReactDOM.render(
        <React.StrictMode>
            <App store={store} history={history} persistor={persistor} />
        </React.StrictMode>,
        document.getElementById('root'),
    )
);


serviceWorker.unregister();
