import React from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import styles from '../../assets/jss/nextjs-material-kit/pages/dashboard.js';
import { Trans } from 'react-i18next';

const useStyles = makeStyles(styles);

const sampleResponse = {
  body: 'upear mopir',
  annotations: [
    {
      type: 'language',
      value: 'es',
      confidence: 0.8732164,
      name: 'spanish',
      iso3: 'spa',
    },
    {
      type: 'language',
      value: 'en',
      confidence: 0.7931245,
      name: 'english',
      iso3: 'eng',
    },
    {
      type: 'language',
      value: 'it',
      hasPosition: false,
      begin: -1,
      end: -1,
      confidence: 0.74899125,
      name: 'italian',
      iso3: 'ita',
    },
    {
      type: 'language',
      value: 'fr',
      hasPosition: false,
      begin: -1,
      end: -1,
      confidence: 0.7040956,
      name: 'french',
      iso3: 'fra',
    },
  ],
};
export default function APIDoc() {
  const classes = useStyles();
  return (
    <div style={{ padding: 40 }}>
      <Typography variant="h4">API Documentations</Typography>
      <br></br>
      <Typography>
        The provided services by AiLab24 are exposed via private REST APIs. Our
        NLP APIs follow a common approach, namely after receiving the text as
        the &quot;body&quot; attribute of the request object, it attaches the
        corresponding annotation objects to the message and return it as
        response json.
      </Typography>
      <br></br>
      <br></br>
      <Typography variant="h5">Language detection</Typography>
      <br></br>
      <Typography>
        Language detection service detects the possible language(s) of the
        passing text. This service supports two endpoints:
        &quot;api/language/detect&quot; and &quot;api/language/detect-all&quot;.
      </Typography>
      <Typography>
        The &quot;detect&quot; endpoint annotate the message with the detected
        language with the highest certainty. If no language is detected or the
        certainty is below the threshold an annotation with &quot;unknown&quot;
        language will be added.
      </Typography>
      <br></br>
      <div className={classes.codeStyle}>
        POST https://api.ailab24.com/api/language/detect
        <br></br>
        {`{ "body":"Es scheint toll" }`}
      </div>
      <br></br>
      <Typography>The response object will be:</Typography>
      <br></br>
      <div className={classes.codeStyle}>{`{
            "body": "Es scheint toll",
            "annotations": [
                {
                    "type": "language",
                    "value": "de",
                    "confidence": 0.9230769,
                    "name": "german",
                    "iso3": "deu"
                }
            ]
        }`}</div>
      <br></br>
      <Typography>
        The detect-all endpoint, on the other hand, returns all matched
        languages with confidence above the threshold.
      </Typography>
      <br></br>
      <div className={classes.codeStyle}>
        POST https://api.ailab24.com/api/language/detect-all
        <br></br>
        {`{"body": "upear mopir"}`}
      </div>
      <br></br>
      <Typography>The response will be:</Typography>
      <br></br>
      <div className={classes.codeStyle}>
        <pre>{JSON.stringify(sampleResponse, null, '\t')}</pre>
      </div>
    </div>
  );
}
