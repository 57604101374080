import { container } from '../../nextjs-material-kit';

const signupPageStyle = theme => ({
  container: {
    display: 'flex',
    height: '100%',
    paddingTop: 76,
    color: '#20236c',
    overflow: 'auto',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  imageTitle: {
    display: 'flex',
    flexDirection: 'column',
    padding: [[50, 0, 0, 50]],
    [theme.breakpoints.down('sm')]: {
      padding: [[50, 10, 0, 10]],
    },
  },
  actionBox: {
    display: 'flex',
    flexDirection: 'column',
    padding: [[200, 0, 0, 50]],
    maxWidth: 600,
    [theme.breakpoints.down('sm')]: {
      padding: [[35, 10, 0, 10]],
      marginBottom: 50,
    },
  },
  bgImag: {
    width: '100%',
    marginTop: 'auto',
    maxHeight: 600,
    [theme.breakpoints.down('sm')]: {
      marginTop: 50,
      width: 'calc(100% - 150px)',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: 50,
      width: 'calc(100% - 20px)',
    },
  },
  navButton: { width: 200, height: 50, maxWidth: 'calc(50% - 10px)' },
});

export default signupPageStyle;
