import React from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
// @material-ui/icons

// core components
import Header from '../../components/Header/Header.js';
import HeaderLinks from '../../components/Header/HeaderLinks.js';
import Footer from '../../components/Footer/Footer.js';

import styles from '../../assets/jss/nextjs-material-kit/pages/planDetail.js';
import wpImg from '../../assets/img/wp-ill.svg';
import magentoImg from '../../assets/img/magento-ill.png';

const useStyles = makeStyles(styles);

export default function Login(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const images = [null, wpImg, magentoImg];
  const titles = [null, 'Wordpress', 'Magento'];
  return (
    <div style={{ height: '100%', background: '#0000000d' }}>
      <Header
        absolute
        // color="transparent"
        brand="AI Labs24"
        rightLinks={<HeaderLinks history={props.history} />}
        {...rest}
      />
      <Container className={classes.container}>
        <Grid className={classes.imageTitle} item md={7} sm={12}>
          <Typography variant="h2">{titles[rest.match.params.id]}</Typography>
          <Typography variant="h4" style={{ marginTop: 30 }}>
            Here is the description for this product
          </Typography>
          <img src={images[rest.match.params.id]} className={classes.bgImag} />
        </Grid>

        <Grid
          className={classes.actionBox}
          style={{ paddingTop: 145 }}
          item
          md={5}
          sm={12}
        >
          <Typography variant="h3">
            Documentation for {titles[rest.match.params.id]}
          </Typography>
          <br />
          <br />
          <br />
          {/* <Typography variant="h4">
            You need to login in order to activate your trial service.
          </Typography> */}
          <br />
          <br />
          <div>
            {/* <Button
              color="primary"
              variant="contained"
              className={classes.navButton}
            >
              Sign Up
            </Button>
            <Button
              variant="text"
              className={classes.navButton}
              style={{ marginLeft: 20 }}
            >
              Login
            </Button> */}
          </div>
        </Grid>
      </Container>
      <Hidden smDown>
        <Footer
          style={{
            position: 'fixed',
            width: '100%',
            bottom: 0,
          }}
        />
      </Hidden>
    </div>
  );
}
