import React, {useEffect, useState} from 'react';
// @material-ui/core components
import {makeStyles} from '@material-ui/core/styles';
import styles from '../../assets/jss/nextjs-material-kit/pages/dashboard.js';
import HttpClient from '../../helpers/HttpClient';
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import {Trans} from "react-i18next";
import Button from "@material-ui/core/Button";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Card from "../../components/Card/Card";
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(styles);

const useStylesInline = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(1),
            width: theme.spacing(16),
            height: theme.spacing(16),
        },
    },
}));

export default function ChangePlan() {
    const classes = useStyles();
    const [plans, setplans] = useState([]);
    const [myplan, setMyPlan] = useState({});
    const [loaded, setLoaded] = useState(false);

    const loadPlans = () => {
        HttpClient
            .get('plan/list', {withCredentials: true}, true)
            .then(response => {
                setplans(response.data);
                HttpClient
                    .get( 'plan/myplan', {withCredentials: true}, true)
                    .then(response => {
                        setMyPlan(response.data);
                        setLoaded(true);
                    })
                    .catch(() => {
                        console.error('load my plan failed!');
                    });
                setLoaded(true);
            })
            .catch(() => {
                console.error('load plans failed!');
            });
    }

    useEffect(() => {
        loadPlans();
    }, []);

    const setActive = (planId) => {
        HttpClient
            .post('plan/myplan/' + planId, {}, {withCredentials: true} , true)
            .then(response => {
                loadPlans();
            })
            .catch(() => {
                console.error('update active plan failed!');
            });
    }

    let planList;
    if (!loaded) {
        planList = <Trans>common-loading</Trans>;
    } else if (loaded) {
        planList = plans.map((p, index) => {
            return (<div style={{display:"block", float:"left", margin:"10px", width: "300px"}}><Card variant="outlined"  >
                <CardContent>
                    <Typography variant="h4" style={{marginTop: '10px'}} gutterBottom>
                        {p.name}
                    </Typography>

                    <div style={{marginTop: '10px'}}>
                        <Typography variant="h5" component="p">
                            {<Trans values={{cost:p.cost}} >pages-dashboard-tools-plan-change-cost</Trans>}
                        </Typography>
                    </div>

                    <div style={{marginTop: '10px'}}>
                        <Typography variant="body2" component="p">
                            <ul>
                                <li>
                                    {<Trans values={{value:p.calls}} >plan-calls-desc</Trans>}
                                </li>
                                <li>
                                    {<Trans>plan-support-desc</Trans>}{p.hasSupport?<CheckIcon color="primary"/>:<CloseIcon color="error"/>}
                                </li>
                                <li>
                                    {<Trans>plan-integration-desc</Trans>}{p.hasIntegration?<CheckIcon color="primary"/>:<CloseIcon color="error"/>}
                                </li>
                            </ul>
                        </Typography>
                    </div>



                </CardContent>
                <CardActions>
                    {p.id != myplan.planId ? <Button onClick={() => setActive(plans[index].id)} variant="contained"
                                                     color="primary"><Trans>common-choose</Trans></Button> :
                        <Button color="secondary"  variant="contained" disabled={true} ><Trans>pages-dashboard-tools-plan-change-current</Trans></Button>}
                </CardActions>
            </Card></div>)
        });
    }

    return (<Container maxWidth="lg">
        <Typography component="div">
            <Typography variant="h3" style={{marginTop: '10px'}} gutterBottom>
                <Trans>pages-dashboard-tools-plan-change</Trans>
            </Typography>

            <div style={{display:"table"}}>
                {planList}
            </div>


        </Typography>

    </Container>);
}
