import React from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// @material-ui/icons
import Chat from '@material-ui/icons/Code';
import VerifiedUser from '@material-ui/icons/Extension';
import Fingerprint from '@material-ui/icons/BarChart';
// core components
import GridContainer from '../../components/Grid/GridContainer.js';
import GridItem from '../../components/Grid/GridItem.js';
import InfoArea from '../../components/InfoArea/InfoArea.js';

import styles from '../../assets/jss/nextjs-material-kit/pages/landingPageSections/productStyle.js';
import { Trans } from 'react-i18next';

const useStyles = makeStyles(styles);

export default function ProductSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>
            <Trans>home-site-intro-title</Trans>
          </h2>
          <h5 className={classes.description}>
            <Trans>home-site-intro-text</Trans>
          </h5>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title={<Trans>home-site-intro-section-services-title</Trans>}
              description={<Trans>home-site-intro-section-services-text</Trans>}
              icon={Chat}
              iconColor="info"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title={<Trans>home-site-intro-section-platform-title</Trans>}
              description={<Trans>home-site-intro-section-platform-text</Trans>}
              icon={VerifiedUser}
              iconColor="success"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title={<Trans>home-site-intro-section-plans-title</Trans>}
              description={<Trans>home-site-intro-section-plans-text</Trans>}
              icon={Fingerprint}
              iconColor="danger"
              vertical
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
