import palette from './palette';
// import createMuiTheme from '@material-ui/core/styles/createMuiTheme';

// const defaultTheme = createMuiTheme();

const theme = {
  palette,
  toolbar: {
    height: 60,
  },
  spacing: 4,
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiInput: {
      root: {
        height: 30,
      },
    },
    MuiOutlinedInput: {
      root: {
        height: 40,
      },
    },
    MuiPrivateNotchedOutline: {
      root: {
        height: 40,
      },
    },
    MuiBackdrop: {
      root: {},
    },
    MuiFilledInput: {
      root: {
        padding: 0,
      },
      input: {
        padding: 0,
        fontSize: '12px',
      },
    },
    MuiInputLabel: {
      outlined: {
        transform: 'translate(14px, 10px) scale(1)',
      },
    },
    props: {
      MuiInputLabel: {
        shrink: true,
      },
      MuiInput: {
        disableUnderline: true,
      },
    },
  },
};

export default theme;
