import React, {useEffect, useState} from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import styles from '../../assets/jss/nextjs-material-kit/pages/dashboard.js';
import HttpClient from '../../helpers/HttpClient';
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import {Trans} from "react-i18next";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import {JsonEditor as Editor} from "jsoneditor-react";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Card from "../../components/Card/Card";
const useStyles = makeStyles(styles);

export default function ManageAPI() {
  const classes = useStyles();
  const [APIkeys, setAPIkeys] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const loadkeys = () => {
    HttpClient
        .get('apikey/list', {withCredentials: true}, true)
        .then(response => {
          setAPIkeys(response.data);
          setLoaded(true);
        })
        .catch(() => {
          console.error('load APIkeys failed!');
        });
  }

  useEffect(() => {
    loadkeys();
  }, []);

  const generateKey = () => {
    HttpClient
        .post( 'apikey/generate', {} , {withCredentials: true}, true)
        .then(response => {
          loadkeys();
        })
        .catch(() => {
          console.error('generate APIkeys failed!');
        });
  }

  let apiKey;
  let expire;
  let hasKey = false;
  if(!loaded) {
    apiKey = <Trans>common-loading</Trans>;
  }else if(loaded && APIkeys.length > 0) {
    hasKey = true;
    apiKey = APIkeys[0].apikey;
    expire = APIkeys[0].expire;
  } else {
    apiKey = <Trans>pages-dashboard-tools-api-keys-empty</Trans>;
  }

  return ( <Container maxWidth="lg">
    <Typography component="div">
      <Typography variant="h3" style={{ marginTop: '10px' }} gutterBottom>
        <Trans>pages-dashboard-tools-api-keys</Trans>
      </Typography>

      <Card variant="outlined">
        <CardContent>
          <Typography variant="h5" style={{ marginTop: '10px' }} gutterBottom>
            <Trans>pages-dashboard-tools-api-keys-current</Trans>
          </Typography>
          <Typography variant="body2" component="p">
            {apiKey}
          </Typography>

          <div style={{ marginTop: '10px' }}>
            {hasKey?
                <Typography variant="h5" style={{ marginTop: '10px' }} gutterBottom>
                  <Trans>pages-dashboard-tools-api-keys-expire</Trans>
                </Typography>
                :""}
            {hasKey?
                <Typography variant="body2" component="p">
                  {expire}
                </Typography>
                :""}
          </div>

        </CardContent>
        <CardActions>
          <Button  onClick={generateKey}  variant="contained" color="primary" ><Trans>common-generate</Trans></Button>
        </CardActions>
      </Card>
    </Typography>

  </Container>);
}
