import React from 'react';
import TextField from "@material-ui/core/TextField";

export default function ToggleTextEdit(props) {
    let {editMode, value, onChange, type } = props;
    if (type == null) {
        type = "text";
    }
    let comp = null;
    if(editMode) {
        comp = <TextField variant="outlined"  type={type} defaultValue={value}
                      inputProps={{ 'aria-label': 'description' }}
                      onChange={onChange}
        />;
    } else {
        comp = <span >
            {value}
        </span>;
    }

    return comp;
}