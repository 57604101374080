import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import styles from '../../assets/jss/nextjs-material-kit/pages/dashboard.js';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ReportIcon from '@material-ui/icons/BarChart';
import PowerIcon from '@material-ui/icons/Power';
import PersonIcon from '@material-ui/icons/Person';
import SettingIcon from '@material-ui/icons/Settings';
import StorageIcon from '@material-ui/icons/Storage';
import HelpIcon from '@material-ui/icons/Help';

const useStyles = makeStyles(styles);

export default function DashboardDrawer(props) {
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const classes = useStyles();
  const { changePage } = props;
  const pages = [
    {
      title: 'Manage API Key',
      icon: <StorageIcon />,
    },
    {
      title: 'Test Our APIs',
      icon: <AssignmentIcon />,
    },
    {
      title: 'Usage Report',
      icon: <ReportIcon />,
    },
    {
      title: 'API Documentations',
      icon: <HelpIcon />,
    },
    {
      title: 'Plugins',
      icon: <PowerIcon />,
    },
    {
      title: 'Subscription Plan',
      icon: <SettingIcon />,
    },
    {
      title: 'Profile',
      icon: <PersonIcon />,
    },
  ];
  return (
    <Drawer
      variant="permanent"
      onClick={open ? handleDrawerClose : handleDrawerOpen}
      className={`${classes.drawer} ${
        open ? classes.drawerOpen : classes.drawerClose
      }`}
      classes={{
        paper: `${open ? classes.drawerOpen : classes.drawerClose} ${
          classes.drawerPaper
        }`,
      }}
    >
      <div className={classes.toolbar}>
        <IconButton
          onClick={open ? handleDrawerClose : handleDrawerOpen}
          className={classes.listIcons}
        >
          {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </div>
      <Divider />
      <List>
        {pages.map((page, index) => (
          <ListItem
            button
            key={index}
            onClick={event => {
              changePage(index);
              event.stopPropagation();
            }}
          >
            <ListItemIcon className={classes.listIcons}>
              {page.icon}
            </ListItemIcon>
            <ListItemText primary={page.title} />
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
}
