import React from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { Trans } from 'react-i18next';

// @material-ui/icons

// core components
import Header from '../../components/Header/Header.js';
import Footer from '../../components/Footer/Footer.js';
import GridContainer from '../../components/Grid/GridContainer.js';
import GridItem from '../../components/Grid/GridItem.js';
import Button from '../../components/CustomButtons/Button.js';
import HeaderLinks from '../../components/Header/HeaderLinks.js';
import Parallax from '../../components/Parallax/Parallax.js';

import styles from '../../assets/jss/nextjs-material-kit/pages/landingPage.js';

// Sections for this page
import ProductSection from './ProductSection.js';
import PlatformsPage from './PlatformsPage';
import TeamSection from './TeamSection.js';
// import WorkSection from './WorkSection.js';
import GetStarted from './GetStarted.js';
import labImg from '../../assets/img/lab-icon.png';

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function LandingPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand="AI Lab"
        rightLinks={<HeaderLinks history={props.history} />}
        otherStyles={{ color: 'black' }}
        fixed
        changeColorOnScroll={{
          height: 400,
          // color: 'white',
        }}
        {...rest}
      />
      <Parallax
        // filter
        // responsive
        image={require('../../assets/img/header-light-narrow.png')}
      >
        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
          <h1 className={classes.title}>AI Lab</h1>
          <h2 className={classes.title24}>24</h2>
        </div>
        <h2 className={classes.subtitle}>
          <span style={{ color: '#f2bc1b', marginRight: 5 }}>AI Lab24 </span>{' '}
          gives your business affordable and easy to integrate cutting edge
          AI-APIs
        </h2>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <a name="intro-section" id="intro-section" />
          <ProductSection />
          <PlatformsPage history={rest.history} />
          <a name="plans-section" id="plans-section" />
          <GetStarted history={rest.history} />
          <a name="team-section" id="team-section" />
          <TeamSection />
        </div>
      </div>
      <Footer />
    </div>
  );
}
