import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import testActions from '../../store/Test/actions';

const Home = ({ ...props }) => {
  const list = useSelector((state) => state.Test.list);
  console.log(list);
  const dispatch = useDispatch();
  return (
    <div>
      <center>
        <Button variant="contained" color="primary" onClick={() => testActions.test()(dispatch)}> Test API </Button>
      </center>
    </div>
  );
};

export default Home;
