import * as types from './constants';

const initialState = {
  user: null,
};

export default function Login(state = initialState, action) {
  switch (action.type) {
    case types.LOGIN:
      return {
        ...state,
        user: action.payload,
      };

    case types.LOGOUT:
      return {
        ...state,
        user: null,
      };

    default:
      return state;
  }
}
