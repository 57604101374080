/*eslint-disable*/
import React from "react";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import {Trans} from 'react-i18next';
import {useSelector, useDispatch} from 'react-redux';
import Button from "../CustomButtons/Button.js";

import userActions from '../../store/User/actions';

import styles from "../../assets/jss/nextjs-material-kit/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
    const classes = useStyles();
    const loggedInUser = useSelector(state => state.User.user);
    const dispatch = useDispatch();
    const isHome = window.location.pathname == "/" ? true : false;
    const isDashboard = window.location.pathname == "/dashboard" ? true : false;
    return (
        <List className={classes.list}>
            {!isHome && <ListItem className={classes.listItem}>
                <Button
                    color="transparent"
                    className={classes.navLink}
                    onClick={() => props.history.push('/')}
                >
                    <Trans>common-home</Trans>
                </Button>
            </ListItem>}
            {isHome && <> <ListItem className={classes.listItem}>
                <Button
                    color="transparent"
                    className={classes.navLink}
                    onClick={() => document.getElementById('intro-section').scrollIntoView({behavior:"smooth"})}
                >
                    <Trans>common-intro</Trans>
                </Button>
            </ListItem>
                <ListItem className={classes.listItem}>
                    <Button
                        color="transparent"
                        className={classes.navLink}
                        onClick={() => document.getElementById('plans-section').scrollIntoView({behavior:"smooth"})}
                    >
                        <Trans>common-plans</Trans>
                    </Button>
                </ListItem>
                <ListItem className={classes.listItem}>
                    <Button
                        color="transparent"
                        className={classes.navLink}
                        onClick={() => document.getElementById('team-section').scrollIntoView({behavior:"smooth"})}
                    >
                        <Trans>common-team</Trans>
                    </Button>
                </ListItem>
            </>
            }
            {!loggedInUser &&
            <>
                <ListItem className={classes.listItem}>
                    <Button
                        color="transparent"
                        className={classes.navLink}
                        onClick={() => props.history.push('/login')}
                    >
                        <Trans>common-login</Trans>
                    </Button>
                </ListItem>
                <ListItem className={classes.listItem}>
                    <Button
                        color="transparent"
                        className={classes.navLink}
                        onClick={() => props.history.push('/signup')}
                    >
                        <Trans>common-signup</Trans>
                    </Button>
                </ListItem>
            </>}
            {loggedInUser && !isDashboard &&
            <>
                <ListItem className={classes.listItem}>
                    <Button
                        color="transparent"
                        className={classes.navLink}
                        onClick={() => props.history.push('/dashboard')}
                    >
                        <Trans>pages-dashboard-title</Trans>
                    </Button>
                </ListItem>
            </>
            }
            {loggedInUser &&
            <>
                <ListItem className={classes.listItem}>
                    <Button
                        color="transparent"
                        className={classes.navLink}
                        onClick={() => {
                            userActions.logout()(dispatch);
                            props.history.push('/');
                        }
                        }
                    >
                        <Trans>pages-dashboard-tools-logout</Trans>
                    </Button>
                </ListItem>
            </>
            }
            <ListItem className={classes.listItem}>
                <Button
                    color="transparent"
                    className={classes.navLink}
                    onClick={() => props.history.push('/status')}
                >
                    <Trans>common-status</Trans>
                </Button>
            </ListItem>
        </List>
    );
}
