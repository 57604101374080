import React from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// @material-ui/icons
import Chat from '@material-ui/icons/Code';
import VerifiedUser from '@material-ui/icons/Extension';
import Fingerprint from '@material-ui/icons/BarChart';
// core components
import GridContainer from '../../components/Grid/GridContainer.js';
import GridItem from '../../components/Grid/GridItem.js';
import InfoArea from '../../components/InfoArea/InfoArea.js';

import styles from '../../assets/jss/nextjs-material-kit/pages/landingPageSections/platformStyle.js';
import { Trans } from 'react-i18next';

import drupal from '../../assets/img/drupal.png';
import magento from '../../assets/img/magento.png';
import wordpress from '../../assets/img/wordpress.png';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(styles);

export default function PlatformSection({ history }) {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>
            <Trans>home-site-platforms-title</Trans>
          </h2>
          <h5 className={classes.description}>
            <Trans>home-site-platforms-desc</Trans>
          </h5>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          <GridItem
            xs={12}
            sm={12}
            md={4}
            className={classes.eachItem}
            onClick={() => history.push('/product/1')}
          >
            <img src={wordpress} className={classes.img}></img>
            <Typography
              className={classes.itemText}
              style={{ color: '#21759b' }}
            >
              WordPress
            </Typography>
          </GridItem>
          <GridItem
            xs={12}
            sm={12}
            md={4}
            className={classes.eachItem}
            onClick={() => history.push('/product/2')}
          >
            <img src={magento} className={classes.img}></img>
            <Typography
              className={classes.itemText}
              style={{ color: '#f26322' }}
            >
              Magento
            </Typography>
          </GridItem>
          <GridItem
            xs={12}
            sm={12}
            md={4}
            className={classes.eachItem}
            style={{ filter: 'grayscale(100%)' }}
          >
            <img src={drupal} className={classes.img}></img>
            <Typography
              className={classes.itemText}
              style={{ color: '#009cde' }}
            >
              Drupal (coming soon)
            </Typography>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
