import React from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
// @material-ui/icons

// core components
import Header from '../../components/Header/Header.js';
import HeaderLinks from '../../components/Header/HeaderLinks.js';
import Footer from '../../components/Footer/Footer.js';

import styles from '../../assets/jss/nextjs-material-kit/pages/planDetail.js';
import image from '../../assets/img/firmware.svg';

const useStyles = makeStyles(styles);

export default function Login(props) {
  const classes = useStyles();
  const user = useSelector(state => state.User.user);
  const LOGGED_IN = user;
  const { ...rest } = props;
  return (
    <div style={{ height: '100%', background: '#0000000d' }}>
      <Header
        absolute
        // color="transparent"
        brand="AI Labs24"
        rightLinks={<HeaderLinks history={props.history} />}
        {...rest}
      />
      <Container className={classes.container}>
        <Grid
          className={classes.imageTitle}
          item
          md={LOGGED_IN ? 9 : 7}
          sm={12}
        >
          {!LOGGED_IN && (
            <Typography variant="h2">
              It looks like you&apos;re not logged in yet...
            </Typography>
          )}
          {LOGGED_IN && (
            <Typography variant="h2">Start using your trial service</Typography>
          )}
          {LOGGED_IN && (
            <Button
              color="primary"
              variant="contained"
              style={{ marginTop: 50 }}
              className={classes.navButton}
            >
              Activate
            </Button>
          )}
          <img src={image} className={classes.bgImag} />
        </Grid>
        {!LOGGED_IN && (
          <Grid className={classes.actionBox} item md={5} sm={12}>
            <Typography variant="h3">One step more!</Typography>
            <br />
            <br />
            <br />
            <Typography variant="h4">
              You need to login in order to activate your trial service.
            </Typography>
            <br />
            <br />
            <div>
              <Button
                color="primary"
                variant="contained"
                className={classes.navButton}
              >
                Sign Up
              </Button>
              <Button
                variant="text"
                className={classes.navButton}
                style={{ marginLeft: 20 }}
              >
                Login
              </Button>
            </div>
          </Grid>
        )}
      </Container>
      <Hidden smDown>
        <Footer
          style={{
            position: 'fixed',
            width: '100%',
            bottom: 0,
          }}
        />
      </Hidden>
    </div>
  );
}
