import React from 'react';
import './StatusItem.css';

function StatusItem(props) {
  return (
    <div className="StatusItem">
      <h3>
        {props.service} - {props.status}
      </h3>
      <span style={{ color: '#ccc' }}>{props.updated}</span>
    </div>
  );
}

export default StatusItem;
